import {Alert, Button, Checkbox, Space} from "antd";
import React, {useState} from "react";
import FormExt from "../../../tk/forms/FormExt";
import useEnumType from "../../../hooks/useEnumType";
import entityDefs from "../entities/entityDefs";
import OverviewLine from "../overviews/OverviewLine";
import PropTypes from "prop-types";
import {emptyEntityRef} from "../../../lib/entityUtils";
import {LayoutWrapper, RenderJournal, RenderStaffs} from "../getjirametadata/ImportDialogeRenderComponents";
import EntityTagList from "../../../tk/bits/EntityTagList";

export const insetStyle = {paddingLeft: "24px", paddingRight: "24px", paddingBottom: "0px", paddingTop: "0"};

export const getEnumRef = (id, options) => {
    const match = options.filter(option => option.id === id)
    return match[0];
}

const ReferenceLookupDialog = (props) => {
    const {result, onImport, onCancel} = props;
    const referenceStatuses = useEnumType('referenceStatuses');
    const referenceTypes = useEnumType('referenceTypes');

    const defaultCheckboxFilters = [
        "staffs", "journal", "datetimeUpdated",
        "uri", "title",
        "year", "source", "volume",
        "pages", "abstract", "id_reference_status",
        "id_reference_type", "matches"
    ]

    const [checkboxFilters, setCheckboxFilters] = useState(defaultCheckboxFilters);
    const indeterminate = checkboxFilters.length > 0 && checkboxFilters.length < defaultCheckboxFilters.length
    const checkedAll = checkboxFilters.length === defaultCheckboxFilters.length
    const handleCheckAllBoxClick = () => {
        const newCheckboxFilters = checkedAll ? [] : defaultCheckboxFilters
        setCheckboxFilters(newCheckboxFilters)
    };
    const filterData = (data) => {
        const dataKeysToBeExcluded = defaultCheckboxFilters.filter(val => !checkboxFilters.includes(val))
        const filteredData = Object.fromEntries(Object.entries(data).filter(([key]) => !dataKeysToBeExcluded.includes(key)))
        return filteredData;
    };
    let existingMatches = [];
    if (result.matches) {
        existingMatches = result.matches.map((item) => ({
            id: item.id,
            name: item.name
        }));
    }

    return (
        <>
            <h3 style={insetStyle}>Result</h3>
            {existingMatches.length > 0 &&
                <>
                    <Alert
                        message="Potential existing matches found"
                        description={<EntityTagList entityDef={entityDefs.reference} list={existingMatches}/>}
                        type="warning"
                        showIcon
                    />
                    <br/>
                </>
            }
            <FormExt
                defaultEntity={{
                    staffs: result.authors ? result.authors.map(author => emptyEntityRef) : [],
                    journal: emptyEntityRef
                }}
                updateEntity={data => {
                    return onImport(filterData({...result, ...data}))
                }}
                globalErrors={[]}
                setGlobalErrors={(e) => console.log("SET GLOBAL", e)}
            >
                <div style={insetStyle}>

                    <Space direction={'vertical'}>
                        <Checkbox
                            indeterminate={indeterminate}
                            onClick={() => handleCheckAllBoxClick()}
                            checked={checkedAll}
                        >
                            {"Select all"}
                        </Checkbox>
                        <Checkbox.Group
                            defaultValue={defaultCheckboxFilters}
                            value={checkboxFilters}
                            style={{
                                width: '100%',
                            }}
                            onChange={(checkedValues) => {
                                setCheckboxFilters(checkedValues);
                            }}
                        >
                            <Space direction={'vertical'} size={'small'} style={{width: '80vw'}}>
                                <LayoutWrapper
                                    checkbox={<Checkbox value={'title'}/>}
                                    component={<OverviewLine label="Title" value={result.title}/>}
                                />
                                <LayoutWrapper
                                    checkbox={<Checkbox value={'year'}/>}
                                    component={<OverviewLine label="Year" value={result.year}/>}
                                />
                                <LayoutWrapper
                                    checkbox={<Checkbox value={'volume'}/>}
                                    component={<OverviewLine label="Volume" value={result.volume}/>}
                                />
                                <LayoutWrapper
                                    checkbox={<Checkbox value={'pages'}/>}
                                    component={<OverviewLine label="Pages" value={result.pages}/>}
                                />
                                <LayoutWrapper
                                    checkbox={<Checkbox value={'source'}/>}
                                    component={<OverviewLine label="Source" value={result.source}/>}
                                />
                                <LayoutWrapper
                                    checkbox={<Checkbox value={'uri'}/>}
                                    component={<OverviewLine label="URI" value={result.uri} type="uri"/>}
                                />
                                <LayoutWrapper
                                    checkbox={<Checkbox value={'abstract'}/>}
                                    component={<OverviewLine label="Abstract" value={result.abstract}/>}
                                />
                                <LayoutWrapper
                                    align={'bottom'}
                                    checkbox={<Checkbox value={'id_reference_status'}/>}
                                    component={
                                        <OverviewLine label="Status"
                                                      value={getEnumRef(result.id_reference_status, referenceStatuses)}
                                                      entityDef={entityDefs.referenceStatus}/>
                                    }
                                />
                                <LayoutWrapper
                                    align={'bottom'}
                                    checkbox={<Checkbox value={'id_reference_type'}/>}
                                    component={
                                        <OverviewLine label="Type"
                                                      value={getEnumRef(result.id_reference_type, referenceTypes)}
                                                      entityDef={entityDefs.referenceType}/>
                                    }
                                />
                                <div style={{padding: "10px"}}/>
                                <LayoutWrapper
                                    renderCondition={result?.authors?.length > 0}
                                    checkbox={<Checkbox value={'staffs'}/>}
                                    component={<RenderStaffs staffs={result.authors}/>}
                                />
                                <LayoutWrapper
                                    renderCondition={result?.journal !== null}
                                    checkbox={<Checkbox value={'journal'}/>}
                                    component={<RenderJournal result={result}/>}
                                />

                            </Space>
                        </Checkbox.Group>
                    </Space>
                </div>

                <div className="ant-modal-footer">
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button type='primary' htmlType='submit'>Update reference</Button>
                </div>
            </FormExt>
        </>
    )
}

ReferenceLookupDialog.propTypes = {
    result: PropTypes.object,
    onImport: PropTypes.func
}

export default ReferenceLookupDialog;
